import React, { useState /*useEffect, useContext*/ } from "react";
import { useNavigate } from "react-router-dom";

// --------------------------------------------- [ STORE ]
import { _store } from './_Context';
import { proxy, useSnapshot, snapshot } from 'valtio'

import { useStoreState, useStoreActions  } from 'easy-peasy';

// --------------------------------------------- [ UTILS ]
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tools from "./Tools" ;


const Login = () => {
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [IsLoggedIn, setIsLoggedIn] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  
  const navigate = useNavigate(); // Utilisé pour rediriger l'utilisateur
  const store = useSnapshot(_store);  
  // window.store = Tools.unproxify(store);

  // const appstore = useStoreState((state) => state);
  // const setValue = useStoreActions((actions) => actions.setValue);

  // Window.appstore = appstore;

  // useEffect(() => {
  //   // if(!store.auth.isAuthenticated) navigate('/');

  // }, []);

  window.tools = Tools;

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page

    // Appel à l'API pour vérifier les identifiants
    const apiUrl = `https://api.agestia.fr/?call=auth&identifiant=${encodeURIComponent(
      username
    )}&pass=${encodeURIComponent(password)}`;

    // Appel à l'API pour vérifier les identifiants
    try {
      const response = await fetch(apiUrl, {
        method: "POST", // Même si on envoie les données dans l'URL, on utilise POST
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      // Vérifie si l'authentification est réussie
      if (data.success) {
        // alert('Connexion réussie !');
        document.cookie = "agestia_auth_token=" + data.token;
        document.cookie = "agestia_logged=true";
        // console.log(data);
        // appstore.auth.isAuthenticated = true;       
        // store.auth.isAuthenticated = true;   
        // setValue(['auth.isAuthenticated', 1]);

        navigate("/users"); // Redirige vers la page dashboard (ou toute autre page)
      } else {
        // En cas d'échec, afficher la raison
        setErrorMessage(data.reason);
      }
    } catch (error) {
      console.error("Erreur lors de la requête:", error);
      setErrorMessage("Une erreur est survenue. Veuillez réessayer.");
    }
  };

  return (

<div id="swup" className="init-users hero min-h-screen bg-base-200 transition-fade">
      {/* <ToastContainer /> */}
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="text-center lg:text-left">          
          <h1 className="text-4xl font-bold">Compte Cabinet </h1>
          <p className="py-6">Merci de vous identifier, grâce au formulaire suivant. </p>
          
          {/* {JSON.stringify(todo.auth)} */}

        </div>
        <div className="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
          <form
            id="agestiam_login"
            className="card-body"
            onSubmit={handleSubmit}
          >
            <div className="form-control">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <input
                id="agestiam_identifiant"
                name="identifiant"
                type="text"
                placeholder="Email"
                className="input input-bordered"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Mot de passe</span>
              </label>
              <input
                id="agestiam_password"
                name="agestiam_password"
                type="password"
                placeholder="Mot de passe"
                className="input input-bordered"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label className="label">
                <a href="/" className="label-text-alt link link-hover">
                  Mot de passe oublié ?
                </a>
              </label>
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
            <div className="form-control mt-6">
              <button type="submit" className="btn btn-primary">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
