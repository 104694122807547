import React, { useState, useEffect } from 'react';
import $ from "jquery";


// --------------------------------------------- [ STORE ]
import { proxy, useSnapshot } from "valtio";
import { _store } from "./_Context.js";


const SvgLoupe = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Capa_1"
    width="512"
    height="512"
    x="0"
    y="0"
    className="fill-current h-6 w-6"
    version="1.1"
    viewBox="0 0 56.966 56.966"
  >
    <path d="M55.146 51.887 41.588 37.786A22.93 22.93 0 0 0 46.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 0 0 .083-4.242M23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17"></path>
  </svg>
);

  // Fonction pour gérer la soumission du formulaire
  // const handleSubmit = async (e) => {
  //   e.preventDefault(); // Empêche le rechargement de la page
  //   a = $('#agestiaTable')
  // };


  const ChampRecherche = (props) => {

    const [searchType,setSearchType] = useState('');
    const store = useSnapshot(_store);


    useEffect(() => {
      console.log(props);
      setSearchType(props.type);
    })

    function setSearch (e) {
      let a = $('#agestiaTable').DataTable();
      if (searchType == "contacts")
        _store.app.contacts = [];
    
      // window.agestiaTable = a;
      // console.log(a);
    }



  return (
    <div className="container mx-auto py-6 container-search">
      <div id="search" className="flex items-center justify-center">
        <div className="flex w-full mx-3 rounded-full bg-slate-100">
          <input
            className="agestiaSearch w-full border-none rounded-full bg-slate-100 px-4 py-1 text-gray-400 outline-none focus:outline-none "
            type="search"
            name="search"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Recherche ..."
          />
          <a
            type="submit"
            className="submit m-2 rounded px-4 py-1 text-slate-300"
          >
            <SvgLoupe />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChampRecherche;
