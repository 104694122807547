import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Drawer } from './Drawer.js'; // Un autre composant 
import { proxy, useSnapshot } from "valtio";
import { _store } from "./_Context.js";

// --------------------------------------------- [ UTILS ]
import { ToastContainer, toast } from 'react-toastify';
import Tools from "./Tools" ;

const Head = () => {  

  const [IsLoggedIn, setIsLoggedIn] = useState();
  const navigate = useNavigate(); // Utilisé pour rediriger l'utilisateur  
  const store = useSnapshot(_store);  

  
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {
    setIsLoggedIn(getCookie("agestia_logged"));
    // setIsLoggedIn( getCookie('agestia_logged') );
    
  }, []);    
  

  
return (

  <div>
    <ToastContainer />
    <dialog id="my_modal_1" className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Hello!</h3>
        <p className="py-4">Etes-vous sur de vouloir vous déconnecter ?</p>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn btn-default btn-confirm btn-confirm-disconnect">Confirmer</button>
            <button className="btn btn-accent btn-close">Annuler</button>
          </form>
        </div>
      </div>
    </dialog>

    <div className="drawer">
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" /> 
      <div className="drawer-content flex flex-col" /*style="font-size : 0.875rem" */>

        <header className="w-full navbar shadow-md fixed bg-base-100 z-10">
          <div className="navbar-start">
            <div className="flex-none lg:hidden">
              <label htmlFor="my-drawer-3" aria-label="open sidebar" className="btn btn-square btn-ghost mr-2">
              <svg id="toggleSidebarMobileHamburger" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
              </label>
            </div> 
            
            <a onClick={() => navigate("dashboard")} className="text-xl init font-extrabold flex items-center lg:ml-2.5">
              <img src="/img/agestia.png" className="h-6 mr-2" alt="Agestia Logo"/>
              <span className="self-center whitespace-nowrap uppercase">Agestia</span>
            </a>   
          </div>

          <div className="navbar-center hidden lg:flex">
              <ul className="menu menu-horizontal px-1">
                <li>
                  <a
                    className="text-lg hover:bg-gray-600 hover:text-white"
                    href="/Dashboard"
                  >
                    Accueil
                  </a>
                </li>
                <li>
                  <a
                    className="text-lg hover:bg-gray-600 hover:text-white"
                    href="Dossiers"
                  >
                    Dossiers
                  </a>
                </li>
                <li>
                  <a
                    className="text-lg hover:bg-gray-600 hover:text-white"
                    href="Contacts"
                  >
                    Contacts
                  </a>
                </li>
                <li>
                  <a
                    className="text-lg hover:bg-gray-600 hover:text-white"
                    href
                  >
                    Facturation
                  </a>
                </li>
                <li>
                  <a
                    className="text-lg hover:bg-gray-600 hover:text-white"
                    href
                  >
                    Agenda
                  </a>
                </li>
                <li>
                  <a
                    className="text-lg hover:bg-gray-600 hover:text-white"
                    href
                  >
                    Transcription
                  </a>
                </li>
              </ul>
            </div>
            <div className="navbar-end">
              <a href className="btn bg-red-200 ">
                Log out
              </a>
            </div>


        </header>
      </div>

      {/* <div className="drawer">
        <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
        <div
          className="drawer-content flex flex-col" /*style="font-size : 0.875rem"
        >
          <div className="navbar bg-base-100">
            <div className="navbar-start">
              <div className="dropdown">
                <div
                  tabIndex={0}
                  role="button"
                  className="btn btn-ghost lg:hidden"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      color="darkblue"
                      d="M4 6h16M4 12h8m-8 6h16"
                    />
                  </svg>
                </div>
                <ul
                  tabIndex={0}
                  className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
                >
                  <li>
                    <a
                      className="text-xl uppercase text-primary hover:bg-gray-600 hover:text-white"
                      href="/Dashboard"
                    >
                      Accueil
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-lg hover:bg-gray-600 hover:text-white"
                      href="/Dossiers"
                    >
                      Dossiers
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-lg hover:bg-gray-600 hover:text-white"
                      href="/Contacts"
                    >
                      Contacts
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-lg hover:bg-gray-600 hover:text-white"
                      href
                    >
                      Facturation
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-lg hover:bg-gray-600 hover:text-white"
                      href
                    >
                      Agenda
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-lg hover:bg-gray-600 hover:text-white"
                      href
                    >
                      Transcription
                    </a>
                  </li>
                </ul>
              </div>
              <img
                src="/img/agestia.png"
                alt="agestia logo"
                className="h-9 mr-2"
              ></img>
              <a href className="btn btn-ghost text-xl">
                Agestia
              </a>
            </div> */}
          {/* </div> */}

      {/*</div>  */}
      {/*</div>  */}

      <Drawer />

      
    </div>
    </div>
  );
};

// export default Head;
export { Head };
