/*eslint no-unused-vars: "off"*/

import React, { useState, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Head } from './components/Head.js'; // Un autre composant 

import Login from './components/Login'; // Ton composant de login
import Dashboard from './components/Dashboard'; // Un autre composant pour la page après login
import Users from './components/Users'; // Un autre composant 
import Dossiers from './components/Dossiers'; // Un autre composant 
import Dossier from './components/Dossier'; // Un autre composant 
import Contacts from './components/Contacts'; // Un autre composant 
import ChampRecherche from './components/ChampRecherche'; // Un autre composant 
import Swup from 'swup';

import 'animate.css';

// import { StoreProvider } from 'easy-peasy';
import store from './components/_Store';




const App = ({ children }) => {
  // const [user, setUser] = useState(null);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  // const navigate = useNavigate();
  // if(!isAuthenticated) navigate('/');
  // element={isAuthenticated ? <Dashboard/> : <Navigate to='/'/>}

  return (
    <Router>
      {/* <StoreProvider store={store}> */}
      <Head /> 
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/users" element={<Users />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dossiers" element={<Dossiers />} />
        <Route path="/dossier/:id" element={<Dossier />} />
        <Route path="/contacts" element={<Contacts />} />
      </Routes>
      {/* </StoreProvider> */}
    </Router>
  );
};
const ConditionalHead = () => {
  const location = useLocation();

  // Pages où le Head ne doit pas s'afficher
  const noHeadPages = ["/", "/users"];

  // Si la route actuelle est dans la liste, ne pas afficher le Head
  if (noHeadPages.includes(location.pathname)) {
    return null;
  }

  return <Head />;
};

export default App;
