
import { createStore, computed, action } from 'easy-peasy';

const auth = {
  users: [], 
  current_user: {}, 
  current_user_id: -1, 
  isAuthenticated: false 
};

const app = {
  api: "Easy-Peasy",
  dossiers: [] ,
  contacts: [],
};


const store = createStore({
  auth : auth,
  app : app,
  setValue : action(( state, payload) =>{
    let field = payload[0];
    let value = payload[1];

    if( Array.isArray(value) )
      state.field.push(value);
    else
      state.field = value;
  }),
  todos: ['Create store', 'Wrap application', 'Use store'],
  test : { a: "1" } ,
  // addTodo: action((state, payload) => {
  //   state.todos.push(payload);
  // }),
});

export default store;