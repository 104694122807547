import { proxy } from 'valtio'

// const _storeAuth = proxy({
//   users: [], 
//   current_user: {}, 
//   current_user_id: -1, 
//   isAuthenticated: false 
// });

// const _storeApp = proxy({
//   dossiers: [] ,
//   contacts: [],
// });

// export {_storeApp, _storeAuth};

const auth = {
  users: [], 
  current_user: {}, 
  current_user_id: -1, 
  isAuthenticated: false 
};

const app = {
  dossiers: [] ,
  contacts: [],
};

let _store = proxy({
  api: "Valtio",
  auth,
  app
});

export { _store };