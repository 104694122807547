// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";
import { ProfileCircle } from "iconoir-react"; // iconoir lib
import Avvvatars from 'avvvatars-react'
import ChampRecherche from "./ChampRecherche.js";

// --------------------------------------------- [ STORE ]
import { proxy, useSnapshot } from "valtio";
import { _store } from "./_Context.js";

// --------------------------------------------- [ UTILS ]
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tools from "./Tools" ;


// --------------------------------------------- [ ICONS ]
import {
  MoreVert,
  Clock,
  Calendar,
  Mail,
  Folder,
  Book,
  UserCircle,
  Microphone,
  } from "iconoir-react"; // iconoir lib


const Contacts = () => {
  const apiUrl = `https://api.agestia.fr/contacts`;
  const token = getCookie("agestia_auth_token");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); // Utilisé pour rediriger l'utilisateur
  
  const notify = () => toast("Bienvenu");
  const store = useSnapshot(_store);
  // const _Auth = useSnapshot(_storeAuth);  
  // if(!_Auth.isAuthenticated) navigate('/');
  
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {
    
    const fetchContacts = async () => {
      // Appel à l'API pour vérifier les identifiants
      // notify();
      try {
        const response = await fetch(apiUrl, {
          method: "GET", // Même si on envoie les données dans l'URL, on utilise POST
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Bearer ${token}`,
          },
        });
        
        const data = await response.json();
        // Vérifie si l'authentification est réussie
        if (data.result === "SUCCESS") {
          // setDatas( data.results );
          _store.app.contacts = data.results;
          window.store = Tools.unproxify(store);

          // setAgestiaTable(a);
          // window.agestiaTable = a;
          // console.log(a);
        } else {
          // En cas d'échec, afficher la raison
          setErrorMessage(data.reason);
        }
      } catch (error) {
        console.error("Erreur lors de la requête:", error);
        setErrorMessage("Une erreur est survenue. Veuillez réessayer.");
      }
    };

    fetchContacts();
  }, []);
  


  document.addEventListener("DOMContentLoaded", (event) => {
    // app.getContacts();
    // if(!app) app = new App();
    // app.render(1,"contacts");
    // $(".agestiaSearch").attr("placeholder", "Recherche ... ");
    // app.getContacts();
    // $("#agestiaTable tbody").on('click','tr',function(e){
    //     // e.stopPropagation()
    //     // let rowID = $(this).attr('id');
    //     rowdata = app.AgestiaTable.row(this).data();
    //     // console.log(rowdata);
    //     //window.location = "/contact.php?c="+rowdata[0];
    //     app.getContact(rowdata[0], false).then( c =>{
    //       // console.log(c);
    //       app.currentcontact = c;
    //       h = app.formatPopupContact(c);
    //       $('#modal_contact .modal-title').html(h.title);
    //       $('#modal_contact .modal-content').html(h.content);
    //       modal_contact.showModal();
    //     })
    //     $(".btn-modify").click( function(){
    //       app.currentcontact.telpro = "123456";
    //       console.log(app.currentcontact);
    //       // app.putContact(rowdata[0],{"telpro":"123456"} );
    //       app.putContact(rowdata[0], app.currentcontact );
    //     })
    // })
  });

  return (
    <div id="swup">
      <div
        className="init container mx-auto p-3 mt-14"
        data-barba="container"
        data-barba-namespace="contacts"
        >
        {/* {store.auth.current_user.nom} */}

        {/* <!-- <button className="btn" onclick="modal_contact.showModal()">open modal</button> --> */}

        <div className="font-bold text-sm breadcrumbs flex">
          <ul className="mx-auto">
            {/* <!-- <li><a href="/"><i className="iconoir-breadcrumb iconoir-home"></i>&nbsp;  ACCUEIL</a></li>  --> */}
            <li>
              <a>
                <i className="iconoir-breadcrumb iconoir-folder"></i>&nbsp;
                CONTACTS
              </a>
            </li>
          </ul>
        </div>

        <ChampRecherche type="contact"/>
        {/* <?php include ("template_parts/field-recherche.php"); ?> */}
        <span id="search-type" data-type="contacts"></span>

        <div className="container mx-auto p-3 text-center">
          <p className="search-notice py-5 text-center">
            Merci de taper votre recherche
            <br />( min. 3 caractères )
          </p>
          <p className="search-notice">
            <i className="iconoir-breadcrumb iconoir-window-no-access text-base-300 text-3xl"></i>
          </p>

          {/* {JSON.stringify(_storeApp.contacts)} */}

          {/* <!-- Component: Two Lines List With Trailing Icon And Leading Avatar --> */}
          <ul className="list divide-y divide-slate-100">
            {store.app.contacts &&
              store.app.contacts.map((contact, index) => (
                <li key={contact.id} className="flex items-center gap-4 px-4 py-3">
                  <div className="shrink-0">
                    <a
                      href="#"
                      className="relative flex items-center justify-center w-10 h-10 text-white rounded-full"
                    >
                      {/* <img src={"https://i.pravatar.cc/40?u="+contact.id}      */}
                      {/* <img src={"https://eu.ui-avatars.com/api/?name="+contact.nom+"+"+contact.prenom+"&size=50"}     
                                 
                        alt="user name"
                        title="user name"
                        width="40"
                        height="40"
                        className="max-w-full rounded-full"
                      /> */}
                      <Avvvatars
                        value={
                          contact.nom[0] + (contact.prenom[0] || contact.nom[1])
                        }
                      />
                      {/* <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                        <span className="sr-only"> offline </span>
                      </span> */}
                    </a>
                  </div>
                  <div className="name flex flex-col gap-0 min-h-[2rem] text-left justify-center w-full min-w-0">
                    <h4 className="w-full text-base truncate text-slate-700 hover:text-emerald-500">
                      {contact.nom} {contact.prenom}
                    </h4>
                    <p className="w-full text-sm truncate text-slate-500">
                      {contact.telpers} / {contact.mail}
                    </p>
                  </div>
                  <a
                    href="#"
                    className="flex items-center justify-center w-6 h-6 text-xs transition-colors text-slate-500 hover:text-emerald-500"
                  >
                    <MoreVert height={16} width={16} />
                    <span className="sr-only">user options</span>
                  </a>
                </li>
              ))}

            {/* <li className="flex items-center gap-4 px-4 py-3">
        <div className="shrink-0">
            <a href="#" className="relative flex items-center justify-center w-10 h-10 text-white rounded-full">
                <img src="https://i.pravatar.cc/40?img=3" alt="user name" title="user name" width="40" height="40" className="max-w-full rounded-full" />
                <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-emerald-500 p-1 text-sm text-white"><span className="sr-only"> online </span></span>
            </a>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] text-left justify-center w-full min-w-0">
            <h4 className="w-full text-base truncate text-slate-700">Thomas Anderson</h4>
            <p className="w-full text-sm truncate text-slate-500">The chosen one, a hacker who can bend the rules of reality.</p>
        </div>
        <a href="#" className="flex items-center justify-center w-6 h-6 text-xs transition-colors text-slate-500 hover:text-emerald-500">
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
            </svg> 
            <span className="sr-only">user options</span>
        </a>
    </li>
    <li className="flex items-center gap-4 px-4 py-3">
        <div className="shrink-0">
            <a href="#" className="relative flex items-center justify-center w-10 h-10 text-white rounded-full">
                <img src="https://i.pravatar.cc/40?img=7" alt="user name" title="user name" width="40" height="40" className="max-w-full rounded-full" />
                <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-emerald-500 p-1 text-sm text-white"><span className="sr-only"> online </span></span>
            </a>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] text-left justify-center w-full min-w-0">
            <h4 className="w-full text-base truncate text-slate-700">Luke Skywalker</h4>
            <p className="w-full text-sm truncate text-slate-500">A Jedi warrior who fights against the dark side of the Force.</p>
        </div>
        <a href="#" className="flex items-center justify-center w-6 h-6 text-xs transition-colors text-slate-500 hover:text-emerald-500">
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
            </svg> 
            <span className="sr-only">user options</span>
        </a>
    </li>
    <li className="flex items-center gap-4 px-4 py-3">
        <div className="shrink-0">
            <a href="#" className="relative flex items-center justify-center w-10 h-10 text-white rounded-full">
                <img src="https://i.pravatar.cc/40?img=5" alt="user name" title="user name" width="40" height="40" className="max-w-full rounded-full" />
                <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-emerald-500 p-1 text-sm text-white"><span className="sr-only"> online </span></span>
            </a>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] text-left justify-center w-full min-w-0">
            <h4 className="w-full text-base truncate text-slate-700">Sarah Connor</h4>
            <p className="w-full text-sm truncate text-slate-500">A fierce resistance leader who fights against machines and cyborgs from the future.</p>
        </div>
        <a href="#" className="flex items-center justify-center w-6 h-6 text-xs transition-colors text-slate-500 hover:text-emerald-500">
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
            </svg> 
            <span className="sr-only">user options</span>
        </a>
    </li>
    <li className="flex items-center gap-4 px-4 py-3">
        <div className="shrink-0">
            <a href="#" className="relative flex items-center justify-center w-10 h-10 text-white rounded-full">
                <img src="https://i.pravatar.cc/40?img=11" alt="user name" title="user name" width="40" height="40" className="max-w-full rounded-full" />
                <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white"><span className="sr-only"> offline </span></span>
            </a>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] text-left justify-center w-full min-w-0">
            <h4 className="w-full text-base truncate text-slate-700">Captain James T. Kirk</h4>
            <p className="w-full text-sm truncate text-slate-500">A charismatic and adventurous Starfleet captain who explores the final frontier.</p>
        </div>
        <a href="#" className="flex items-center justify-center w-6 h-6 text-xs transition-colors text-slate-500 hover:text-emerald-500">
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
            </svg> 
            <span className="sr-only">user options</span>
        </a>
    </li> */}
          </ul>
          {/* <!-- End Two Lines List With Trailing Icon And Leading Avatar --> */}

          <table
            id="agestiaTable"
            className="table stripe hover content-main hidden w-full"
          >
            <thead>
              <tr>
                <th>id</th>
                <th>Nom</th>
                {/* <!-- <th>Entité</th> --> */}
                <th>Téléphone</th>
                <th>Adresse</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
